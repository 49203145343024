<template>
	<div class="container">
		<Nav :curIndex="6"></Nav>
		<div class="account_right">
			<div class="form-box">
				<div class="title">My Profile</div>
				<el-form :model="form" :rules="rules" ref="form" label-position="top" :hide-required-asterisk="true" class="form">
					<el-form-item label="First name" prop="first_name">
					    <el-input v-model="form.first_name" placeholder=""></el-input>
					</el-form-item>
				    <el-form-item label="Last name" prop="last_name">
				        <el-input v-model="form.last_name" placeholder=""></el-input>
				    </el-form-item>
				    <el-form-item label="Gender" prop="gender">
				        <el-radio-group v-model="form.gender">
				              <el-radio label="1">Male</el-radio>
				              <el-radio label="2">Female</el-radio>
							  <el-radio label="3">Secret</el-radio>
				            </el-radio-group>
				    </el-form-item>
				    <el-form-item label="Phone" prop="mobile">
				        <el-input v-model="form.mobile" placeholder=""></el-input>
				    </el-form-item>
					<el-form-item label="Country" prop="country">
					    <el-input v-model="form.country" placeholder=""></el-input>
					</el-form-item>
					<el-form-item label="State" prop="state">
					    <el-input v-model="form.state" placeholder=""></el-input>
					</el-form-item>
					<el-form-item label="City" prop="city">
					    <el-input v-model="form.city" placeholder=""></el-input>
					</el-form-item>
					<el-form-item label="Address" prop="address">
					    <el-input v-model="form.address" placeholder=""></el-input>
					</el-form-item>
					<el-form-item label="Postal code" prop="post_code">
					    <el-input v-model="form.post_code" placeholder=""></el-input>
					</el-form-item>
				    <el-form-item>
				        <div class="submit-btn" @click="submit">Submit</div>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>

<script>
    export default {
		components: {
		    Nav: () => import('@/views/account/nav.vue')
		},
        data() {
            return {
				form: {
					first_name: '',
				    last_name: '',
				    gender: '',
				    mobile: '',
				    country: '',
				    state: '',
					city: '',
					address: '',
					post_code: ''
				},
				rules: {
					first_name: [
						{ required: true, message: 'Please enter first name', trigger: 'blur' }
					],
				    last_name: [
				        { required: true, message: 'Please enter last name', trigger: 'blur' }
				    ],
				    gender: [
				        { required: true, message: 'Please select gender', trigger: 'blur' }
				    ],
				    mobile: [
				        { required: true, message: 'Please enter your phone', trigger: 'blur' }
				    ],
				    country: [
				        { required: true, message: 'Please enter country', trigger: 'blur' }
				    ],
					state: [
					    { required: true, message: 'Please enter state', trigger: 'blur' }
					],
					city: [
					    { required: true, message: 'Please enter city', trigger: 'blur' }
					],
					address: [
						{ required: true, message: 'Please enter address', trigger: 'blur' }
					],
					post_code: [
						{ required: true, message: 'Please enter postal code', trigger: 'blur' }
					]
				}
            }
        },
        created() {
            this.getUserInfo()
        },
        methods: {
            getUserInfo() {
                this.$api.user_info().then(res => {
                    Object.keys(this.form).forEach(key => {
                         this.form[key] = res.data[key] || ''
                    })
                })
            },
            
            submit() {
				this.$refs.form.validate((valid) => {
				    if(valid) {
                        this.$api.set_user_info(this.form,true).then(res => {
                            this.$message({
                                message: res.message,
                                type: 'success'
                            })
                        })
				    }
				})
			}
        }
    }
</script>

<style lang="scss" scoped>
    // PC
    @media only screen and (min-width: 1024px) {
        .container {
        	display: flex;
        	width: 100%;
        	box-sizing: border-box;
        	padding: 4rem 0;
        	
        	.account_right {
        		width: 79%;
        		box-sizing: border-box;
        		padding-left: 3rem;
        		
        		.form-box {
        			width: 100%;
        			
        			.title {
        				width: 100%;
        				margin-bottom: 3rem;
        				font-size: 2rem;
        				color: #333333;
        				word-wrap: break-word;
        			}
        			
        			.form {
        			    width: 100%;
        			    
        			    /deep/ .el-form-item {
        			        margin-bottom: 2.5rem;
        			    }
        			    
        			    .submit-btn {
        			        display: inline-block;
        			        min-width: 32rem;
        			        height: 5rem;
        			        line-height: 5rem;
        			        box-sizing: border-box;
        			        padding: 0 2.5rem;
        			        border-radius: 4px;
        			        font-size: 1.4rem;
        			        color: #FFFFFF;
        			        text-align: center;
        			        white-space: nowrap;
        			        background-color: #333333;
        			        cursor: pointer;
        			    }
        			}
        		}
        	}
        }
    }
    
    // 手机
    @media only screen and (max-width: 1024px) {
        .container {
        	width: 100%;
        	box-sizing: border-box;
        	padding: 2rem $h5-box-padding;
        	
        	.account_right {
        		width: 100%;
        		
        		.form-box {
        			width: 100%;
        			
        			.title {
        				width: 100%;
        				margin-bottom: 2rem;
        				font-size: 1.8rem;
        				color: #333333;
        				word-wrap: break-word;
        			}
        			
        			.form {
        			    width: 100%;
        			    
        			    /deep/ .el-form-item {
        			        margin-bottom: 2.5rem;
        			    }
        			    
        			    .submit-btn {
        			        width: 100%;
        			        height: 4.5rem;
        			        line-height: 4.5rem;
        			        box-sizing: border-box;
        			        padding: 0 2rem;
        			        border-radius: 4px;
        			        font-size: 1.4rem;
        			        color: #FFFFFF;
        			        text-align: center;
        			        white-space: nowrap;
        			        background-color: #333333;
        			        cursor: pointer;
        			    }
        			}
        		}
        	}
        }
    }
</style>